import React, {useEffect} from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import BlogPosts from '../components/blog/blogPosts'
import BlogPostsMobile from '../components/blog/blogPostsMobile'
import '../sass/blog.scss';


const parseBlogPosts = (edges) =>
  edges
    .filter((edge) => edge.node.frontmatter.status)
    .map(({ node }) => ({
      id: node.id,
      path: node.frontmatter.path,
      date: node.frontmatter.date,
      title: node.frontmatter.title,
      imgDesk: node.frontmatter.imgDesk,
      imgMobile: node.frontmatter.imgMobile,
      description: node.frontmatter.description,
      lng: node.frontmatter.lng,
    }))


const BlogPage = ({ data: {
  allMarkdownRemark: { edges },
}, location
}) => {
  const { language } = useI18next();

  const posts = parseBlogPosts(edges).filter(function (el) {
    return el.lng === language
  })

  const breakpoints = useBreakpoint();
  const mobile = breakpoints.mobile

  const { t } = useTranslation()

  const dataGlobal = t("global", { returnObjects: true })
  const dataBlog = t("blog", { returnObjects: true })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (location.search === '')
        window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Layout mobile={mobile} post={null} data={dataGlobal} page={dataBlog.page} seo={dataBlog.seo} >
      {mobile ?
        <>
          <MenuGlobal video src="https://news.invisual.pt/wp/wp-content/uploads/2024/11/MOBILE-UNIVERSO-IN.mp4"
            global={dataGlobal} textstatic={dataBlog} centered={true}
            location={location} />
          {posts &&
            <SmoothScroll>
              <BlogPostsMobile posts={posts} data={dataBlog} />

              <div className="container-scroll-heigth" />

            </SmoothScroll>
          }
        </>
        :
        <>
          <MenuGlobal video src="https://news.invisual.pt/wp/wp-content/uploads/2024/11/Desktop-Universo-IN.mp4"
            global={dataGlobal} textstatic={dataBlog} centered={true}
            location={location} />

          {posts &&
            <SmoothScroll>
              <BlogPosts posts={posts} data={dataBlog} />

              <div className="container-scroll-heigth" />

            </SmoothScroll>
          }
        </>

      }
    </Layout>)
}

export default BlogPage

export const pageQuery = graphql`
    query($language: String!) {
      locales: allLocale(filter: {ns: {in: ["global", "blog"]}, language: {eq: $language}}) {
      edges {
      node {
      ns
          data
    language
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            imgDesk
            imgMobile
            status
            description
            lng
            group
          }
        }
      }
    }
  }
`