import React, { useState } from "react";
import { Link } from "gatsby";

import GeneralButton from "../generalButton";

const BlogPostsMobile = ({ posts, data }) => {

    const [postsNumber, setPostsNumber] = useState(2)

    const seeMorePosts = () => setPostsNumber(postsNumber + 4)

    const visiblePosts = posts.slice(0, postsNumber)

    return (
        <div className="container-margin  m-auto text-center ">
            <div className="grid  container-blog-posts">
                {visiblePosts.map((p, i) => (
                    <Link to={p.path} key={p.path} className="text-left container-card-post-main">
                        <div className="text-left container-card-post">
                            <div className="container-image mb-7">
                                <img src={p.imgDesk} alt={p.title} />
                            </div>
                            <div className="container-card-post-text">
                                <p className="blue text-left ILightBeta uppercase fs-6 post-title">{p.title}</p>
                                <p className="grey text-left ISemiBold fs-5 ls-2 mb-1 mt-1">{p.description}</p>
                                <GeneralButton text={data.posts.btn} url={p.path} center={false} name="ml-auto mr-0"/>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>

            {posts.length > postsNumber && (
                <div className="m-auto text-center container-btn-see-more">
                    {/*  <section className="buttons">
                        <a href="https://twitter.com/Dave_Conner" className="btn btn-1 MExtraBold title-white-border fs-4">
                            <svg>
                                <rect x="0" y="0" fill="none" width="100%" height="100%" />
                            </svg>
                            {data.posts.seemore}
                        </a>
            </section>*/}
                    <div onClick={() => seeMorePosts()} onKeyDown={() => seeMorePosts()} role="presentation" className="pointer-cursor">
                    <svg width="250.668" height="51.638" viewBox="0 0 200.668 51.638">
                            <path id="Caminho_7032" data-name="Caminho 7032" d="M-17345.74-41.868h-13.395v-39.8h223.965v39.8H-17301.2" transform="translate(17360.133 82.668)"
                                fill="none" stroke="#fff" strokeWidth="2" />
                            <foreignObject x="15" y="5" width="200" height="160">
                                <div xmlns="http://www.w3.org/1999/xhtml">
                                    <div className="title-white-border uppercase " style={{ border: "4px solid transparent" }}>{data.posts.seemore}</div>
                                </div>
                            </foreignObject>
                        </svg>
                    </div>
                </div>
            )}

        </div>
    );
}
export default BlogPostsMobile;