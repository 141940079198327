import React, { useState } from "react";
import { Link } from "gatsby";

import GeneralButton from "../generalButton";

const BlogPosts = ({ posts, data }) => {

    const [postsNumber, setPostsNumber] = useState(2)

    const seeMorePosts = () => setPostsNumber(postsNumber + 4)

    const visiblePosts = posts.slice(0, postsNumber)

    return (
        <div className="container-margin  m-auto text-center ">
            <div className="grid two-columns container-blog-posts">
                {visiblePosts.map((p, i) => (
                    <div key={p.path} className="text-left container-card-post-main">
                        <div className="text-left container-card-post">
                            <Link to={p.path} className="container-image mb-7">
                                <img src={p.imgDesk} alt={p.title} />
                            </Link>
                            <div className="container-card-post-text">
                                <p className="blue text-left ILightBeta uppercase fs-6 post-title">{p.title}</p>
                                <p className="grey text-left ISemiBold fs-5 ls-2 mb-1 mt-2">{p.description}</p>
                                <div className=" text-left white mt-2 fs-4 uppercase">
                                    <GeneralButton text={data.posts.btn} url={p.path} center={false} name="ml-auto mr-0"  />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {posts.length > postsNumber && (
                <div className="m-auto text-center container-btn-see-more">
                    <div onClick={() => seeMorePosts()} onKeyDown={() => seeMorePosts()} role="presentation"
                        className="pointer-cursor general-btn-link m-auto text-center">
                        <GeneralButton text={data.posts.seemore} url={null} center={true} />
                    </div>
                </div>
            )}
        </div>
    );
}
export default BlogPosts;